<template>
  <div>
    <div class="header">
      <div class="logo">
        <img src="../assets/image/logo.png" alt="logo" @click="goHome()" style="cursor: pointer" />
      </div>
      <div class="line"></div>
      <div style="width: 1200px; position: relative; height:400px; margin: auto">
        <div class="abortUs">关于我们</div>
        <div class="abortUs-info">
          致力于打造一流的商城、物流综合服务场景，成为更值得信赖的服务专家
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-title">
        <img src="../assets/image/公司简介@2x.png" alt="">
      </div>
      <div class="intro-div">
        <img src="../assets/image/公司简介图@2x.png" alt="">
        <div class="intro-info">
          <div class="intro-title">途啦 TULA</div>
          <div class="intro-content">福建省途啦网络科技有限公司是惠安交发集团与福建汇益众成物流科技有限公司共同出资成立的混合所有制企业，注册资本1000万元。公司致力于在泉州本地打造以服务企业为核心的网络货运平台和建材供应链平台。平台定位为提供B2B服务的移动互联平台，专做公路整车运输及建材供应链服务。运用“互联网+”理念打造车货需求智能匹配及建材交易的电商平台，为不同货主、车主提供相应的找车、拉货、交易等服务。</div>
        </div>
      </div>
    </div>
    <footerComponents ref="footer"></footerComponents>
  </div>
</template>

<script>
import footerComponents from "@/components/footer";
export default {
  data() {
    return {};
  },
  components: { footerComponents },
  methods: {
    goHome() {
      this.$router.push({
        name: 'index'
      })
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  background: url(../assets/image/intro.jpg) no-repeat;
  background-size: 100% auto;
  background-position: center;
  color: #ffffff;
  .logo {
    padding: 20px;
    width: 1200px;
    margin: auto;
    img {
      width: 80px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #b0b0b0;
  }
  .abortUs {
    position: absolute;
    left: 25%;
    top: 125px;
    font-size: 22px;
  }
  .abortUs-info {
    position: absolute;
    left: 25%;
    top: 185px;
    font-size: 18px;
  }
}
.main {
  width: 1200px;
  margin: auto;
  overflow: hidden;
  .main-title {
    text-align: center;
    padding: 80px 0 70px;
  }
  .intro-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
    .intro-info {
      width: 630px;
      display: inline-block;
    }
    .intro-title {
      font-size: 32px;
      color: #ff5b0c;
      font-weight: bold;
      margin-bottom: 25px;
    }
    .intro-content {
      font-size: 20px;
      line-height: 30px;
      color: #333333;
    }
  }
}
</style>